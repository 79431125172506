import React from 'react';
import { Security } from '@okta/okta-react';
import { OAuthError, OktaAuth } from '@okta/okta-auth-js';
import urlcat from 'urlcat';
import oktaConfig from './config/oktaConfig';

// Cypress works better if we don't have extra requirements around auth.
if ((window as any).Cypress) {
  delete oktaConfig.transformAuthState;
}

export const oktaAuth = new OktaAuth(oktaConfig);

const OktaAuthSecurity: React.FC = ({ children }) => {
  const customAuthHandler = async (oa: OktaAuth) => {
    return oa.token
      .getWithoutPrompt({
        responseType: ['id_token', 'token'],
      })
      .then((res) => {
        const { tokens } = res;
        oa.removeOriginalUri();
        oa.tokenManager.setTokens(tokens);
        oa.authStateManager.updateAuthState();
      })
      .catch((err) => {
        if (
          !(window as any).Cypress &&
          err instanceof OAuthError &&
          err.errorCode === 'login_required'
        ) {
          const originalUri = oa.getOriginalUri();
          window.location.href = urlcat(oktaConfig.url, 'login', {
            returnTo: originalUri,
          });
          return;
        }
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler}>
      {children}
    </Security>
  );
};

export default OktaAuthSecurity;
