import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import appConfig from './appConfig';

const baseOktaConfig: OktaAuthOptions = {
  redirectUri: '/okta/callback',
  postLogoutRedirectUri: '/loggedOut',
  scopes: ['openid', 'oneid', 'profile', 'email'],
  pkce: true,
  devMode: process.env.NODE_ENV === 'development',
  cookies: {
    secure: true,
  },
  tokenManager: {
    storage: 'sessionStorage',
  },
  transformAuthState: async (oa: Partial<OktaAuth>, authState) => {
    if (!authState.isAuthenticated) {
      return authState;
    }
    const sessionExists = await oa?.session?.exists();
    if (!sessionExists) {
      oa?.tokenManager?.clear();
    }

    return { ...authState, isAuthenticated: sessionExists };
  },
};

const matchesReactAppTarget = (env: string) =>
  env.toLowerCase() === process.env.REACT_APP_TARGET?.toLowerCase();

const matchesEnvironment = (env: string) =>
  process.env.REACT_APP_TARGET
    ? matchesReactAppTarget(env)
    : window.location.hostname.startsWith(env);

const pickConfig = (): OktaAuthOptions & { url: string } => {
  if (
    matchesEnvironment(appConfig.aws?.appName as string) ||
    matchesEnvironment('production')
  ) {
    return {
      url: 'https://passport.bandwidth.com',
      ...appConfig?.passport?.prod,
      ...baseOktaConfig,
    };
  }

  if (matchesEnvironment('uat')) {
    return {
      url: 'https://uat.passport.bandwidth.com',
      ...appConfig?.passport?.uat,
      ...baseOktaConfig,
    };
  }

  if (matchesEnvironment('ci')) {
    return {
      url: 'https://ci.passport.bandwidth.com',
      ...appConfig?.passport?.ci,
      ...baseOktaConfig,
    };
  }

  if (matchesEnvironment('dev')) {
    return {
      url: 'https://dev.passport.bandwidth.com',
      ...appConfig?.passport?.dev,
      ...baseOktaConfig,
    };
  }

  // default to stage
  return {
    url: 'https://stage.passport.bandwidth.com',
    ...appConfig?.passport?.stage,
    ...baseOktaConfig,
  };
};

const oktaConfig = pickConfig();
export default oktaConfig;
