import configJSON from './appConfig.json';

export interface AppConfigType {
  cypress?: {
    projectId?: string;
  };
  aws?: {
    teamName?: string;
    appName?: string;
  };
  artifactory?: {
    artifactoryProjectDev?: string;
    artifactoryProjectProd?: string;
  };
  passport?: Partial<
    Record<
      'stage' | 'ci' | 'dev' | 'pr' | 'uat' | 'prod',
      { clientId?: string; issuer?: string }
    >
  >;
}

export default configJSON as AppConfigType;
